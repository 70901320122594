<template>
  <div>
    <div class="master-detail-caption">
      <b-button
        style="background: #0A65FF; border-radius: 4px; color: #ffffff; font-weight: 600"
        @click.prevent.stop="showModalAdd = true"
        :class="hasPermission('user/add') ? '' : 'hidden-button'"
      >
        <i class="fa fa-plus-circle" style="color: #ffffff;"></i>Tambah
        Progress</b-button
      >
    </div>
    <DxDataGrid
      :ref="dataGridRefKey"
      :data-source="dataSource"
      :show-borders="true"
      :column-auto-width="true"
      :word-wrap-enabled="true"
    >
      <DxScrolling
        :use-native="true"
        :scroll-by-content="true"
        :scroll-by-thumb="true"
        show-scrollbar="onHover"
      />
      <DxColumn data-field="id" sort-order="desc" :visible="false" />
      <DxColumn data-field="laporan" />
      <DxColumn data-field="tanggal" data-type="date" format="dd/MM/yyyy" />
      <DxColumn
        :calculate-cell-value="downloadUrls"
        data-type="string"
        caption="Download"
        cell-template="cellTemplate"
      />
      <DxColumn
        type="buttons"
        caption="Aksi"
        :visible="hasPermission('user/delete')"
      >
        <DxButton icon="fa fa-trash" hint="Hapus" :onClick="onClickDelete" />
      </DxColumn>
      <template #cellTemplate="{ data }">
        <div v-html="data.value"></div>
      </template>
    </DxDataGrid>

    <!--   Modal Tambah Advokasi Progress  -->
    <b-modal
      v-model="showModalAdd"
      hide-footer
      centered
      size="xl"
      id="modal-tambah-advokasi-progress"
    >
      <template #modal-header="{ close }">
        <h5 class="title-card-custom">Tambah Advokasi Progress</h5>
      </template>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group
          id="example-input-group-1"
          label-for="input-laporan"
          label="Laporan"
        >
          <b-form-input
            id="input-laporan"
            name="input-laporan"
            placeholder="Laporan"
            v-model="form.laporan"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="path-group"
          label-for="path"
          label="Upload Produk"
          description="Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)"
        >
          <b-form-file
            ref="files"
            placeholder="Pilih File"
            drop-placeholder="Drop file di sini..."
            accept=".pdf, .doc, .docx"
            v-model="form.path"
            browse-text="Cari"
            multiple
            :file-name-formatter="formatNames"
            @change="
              validateFileTypeGeneral($event);
              fileSizeChecker('10', $event)"
          ></b-form-file>
        </b-form-group>

        <b-form-group id="tanggal-group" label-for="tanggal" label="Tanggal">
          <b-form-datepicker
            id="tanggal"
            v-model="form.tanggal"
            class="mb-2"
            placeholder="Pilih tanggal progres"
            locale="id"
          ></b-form-datepicker>
        </b-form-group>
        <hr />
        <div class="d-flex justify-content-center">
          <button type="reset" class="btn btn-delete mr-8" @click="resetForm()">
            Kembali
          </button>
          <button
            type="submit"
            class="btn btn-save"
            :class="
              disabledSave === true ? 'spinner spinner-light spinner-right' : ''
            "
            :disabled="disabled"
          >
            Simpan
          </button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { DxDataGrid, DxColumn, DxButton, DxScrolling } from "devextreme-vue/data-grid";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { handleErrors } from "@/core/appUtil/util";
import Swal from "sweetalert2";
import moment from "moment";
import { mapGetters } from "vuex";
import {
  fileSizeChecker,
  validateFileTypeGeneral,
} from "@/core/helpers/functions";

let token = "";
const swalDelete = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-delete",
    cancelButton: "btn btn-cancel"
  },
  buttonsStyling: false
});

const dataGridRefKey = "advokasi-progress-data-grid";

export default {
  mixins: [fileSizeChecker, validateFileTypeGeneral],
  components: { DxDataGrid, DxColumn, DxButton, DxScrolling },
  props: {
    templateData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dataSource: this.getTasks(this.templateData.data.progress),
      showModalAdd: false,
      disabledSave: false,
      disabled: false,
      dataGridRefKey,
      form: {
        laporan: "",
        path: null,
        tanggal: "",
        directorate: null
      }
    };
  },
  computed: {
    ...mapGetters({
      hasPermission: "token/hasPermission"
    }),
    dataGrid: function() {
      return this.$refs[dataGridRefKey].instance;
    }
  },
  mounted() {
    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
    });
  },
  methods: {
    refreshData() {
      this.dataGrid.refresh();
    },
    downloadUrls(rowData) {
      let paths = rowData.path.split(";");
      let output = "";
      if (paths[0].length > 0) {
        output = `<ul>`;
        paths.forEach(path => {
          let fnames = path.split("/");
          let namaFile = fnames[1].substring(8);

          // START Check if new format file name
          let uniqueCode = fnames[1].split("-").pop();
          if (uniqueCode.length > 0) {
            uniqueCode = uniqueCode.split(".")[0];
          }

          let isUniqueCode = uniqueCode ? uniqueCode.length === 7 : false;
          if (isUniqueCode) {
            // Get string before extension
            namaFile = fnames[1].replace(`-${uniqueCode}`, "");
          }
          // END Check if new format file name

          output += `<li><a target="_blank" href="${process.env.VUE_APP_FILE_DOWNLOAD_PATH}/${path}">${namaFile}</a></li>`;
        });
        output += "</ul>";
      } else {
        output = `${rowData.path}`;
      }
      return output;
    },
    getTasks(theData) {
      return new DataSource({
        store: new ArrayStore({
          data: theData,
          key: "id"
        })
      });
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} file terpilih`;
    },
    async onSubmit() {
      // let id = this.form.code;
      this.disabled = true;
      this.disabledSave = true;
      if (this.form.path !== null) {
        let dataUpload = new FormData();
        for (const file of this.form.path) {
          dataUpload.append("files", file, file.name);
        }
        await fetch(
          encodeURI(process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`),
          {
            method: "POST",
            headers: {
              Authorization: "bearer " + token
            },
            body: dataUpload
          }
        )
          .then(handleErrors)
          .then(response => response.json())
          .then(async dataHasilUpload => {
            let advocacyProgress = {
              advokasi_id: this.templateData.data.id,
              laporan: this.form.laporan,
              path: dataHasilUpload.data.join(";"),
              tanggal: moment.utc(this.form.tanggal, "YYYY-MM-DD").format()
            };
            await fetch(
              encodeURI(process.env.VUE_APP_URL_LOCAL + `/advokasi-progress`),
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "bearer " + token
                },
                body: JSON.stringify(advocacyProgress)
              }
            )
              .then(handleErrors)
              .then(response => response.json())
              .then(dataHasilProgress => {
                Swal.fire({
                  position: "center",
                  icon: dataHasilProgress.status,
                  title: "Data berhasil ditambahkan",
                  showConfirmButton: false,
                  timer: 1500
                });
                this.$emit("add-progress", dataHasilProgress);
                this.disabled = false;
                this.disabledSave = false;
              });
          });
      } else {
        let advokasiProgress = {
          advokasi_id: this.templateData.data.id,
          laporan: this.form.laporan,
          tanggal: moment.utc(this.form.tanggal, "YYYY-MM-DD").format()
        };
        await fetch(
          encodeURI(process.env.VUE_APP_URL_LOCAL + `/advokasi-progress`),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + token
            },
            body: JSON.stringify(advokasiProgress)
          }
        )
          .then(handleErrors)
          .then(response => response.json())
          .then(dataHasilProgress => {
            Swal.fire({
              position: "center",
              icon: dataHasilProgress.status,
              title: "Data berhasil ditambahkan",
              showConfirmButton: false,
              timer: 1500
            });
            this.$emit("add-progress", dataHasilProgress);
            this.disabled = false;
            this.disabledSave = false;
          });
      }
    },
    resetForm() {
      this.form = {
        laporan: "",
        tanggal: "",
        path: null
      };

      this.$nextTick(() => {
        this.$bvModal.hide("modal-tambah-advokasi-progress");
      });
    },
    onClickDelete(e) {
      let id = e.row.data.id;
      swalDelete.fire({
        text: "Yakin ingin hapus data?",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        confirmButtonText: "Hapus",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(
              process.env.VUE_APP_URL_LOCAL + `/advokasi-progress/${id}`
            ),
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              }
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              console.log(data);
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil dihapus",
                showConfirmButton: false,
                timer: 1500
              });
              this.$emit("delete-progress", error);
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    }
  }
};
</script>
<style>
.master-detail-caption {
  padding: 0 0 5px 10px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
}
</style>
