<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <!--      <button class="btn btn-secondary mb-7" @click="$router.back()">
        <i class="fa fa-arrow-left"></i>Kembali
      </button>-->
      <div class="row">
        <div class="col-xl-12">
          <div class="card card-custom gutter-b card-stretch">
            <!--begin::Body-->
            <div class="card-body">
              <div class="justify-content-between d-flex">
                <div class="d-flex">
                  <b-form
                    @submit.prevent
                    class="mr-5 mb-8"
                    style="width: 300px; border-radius: 4px;"
                  >
                    <div
                      class="input-group input-group-solid input-group-sm"
                      style="background: #DAE1EC; height: 38px"
                    >
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="email"
                        autocomplete="off"
                        placeholder="Search"
                        v-model="search"
                        ref="search"
                        @keyup.enter="searchAdvocacy()"
                      />
                      <button
                        id="button-addon2"
                        class="btn btn-cari"
                        type="button"
                        @click="searchAdvocacy"
                      >
                        <span v-if="!loading"
                          ><span class="svg-icon svg-icon-sm">
                            <inline-svg src="/media/svg/custom/search.svg" />
                          </span>
                        </span>
                        <b-spinner medium v-else></b-spinner>
                      </button>
                    </div>
                  </b-form>
                  <b-form class="mr-5">
                    <b-form-group
                      id="input-group-3"
                      label=""
                      label-for="input-3"
                    >
                      <b-form-select
                        id="input-3"
                        v-model="form.tahun"
                        style="background-color: #dae1ec"
                        :options="tahuns"
                        required
                        @change="onSelectTahun"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >Tahun:</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-form>
                </div>
                <div class="d-flex mb-7">
                  <b-form class="mr-5">
                    <b-dropdown split text="Cetak" variant="secondary">
                      <b-dropdown-item href="#" @click="exportToExcel"
                        >Excel</b-dropdown-item
                      >
                      <b-dropdown-item href="#" @click="exportToPDF"
                        >PDF</b-dropdown-item
                      >
                    </b-dropdown>
                  </b-form>

                  <b-form>
                    <button
                      type="button"
                      class="hidden-button"
                      @click="refreshData"
                      id="refreshButton"
                      >
                      <i class="fas fa-sync-alt"></i>
                    </button>
                    <router-link :to="{ name: 'TambahAdvokasi' }">
                      <b-button
                        style="background: #0A65FF; border-radius: 4px; color: #ffffff; font-weight: 600"
                        :class="
                          hasPermission('user/add') ? '' : 'hidden-button'
                        "
                      >
                        <i class="fa fa-plus-circle" style="color: #ffffff;"></i
                        >Tambah Data</b-button
                      >
                    </router-link>
                  </b-form>
                </div>
              </div>
              <!--begin::Table-->
              <DxDataGrid
                :ref="dataGridRefKey"
                :data-source="dataSource"
                @selection-changed="selectionChanged"
                key-expr="id"
                :show-borders="true"
                :word-wrap-enabled="true"
                :column-auto-width="true"
                :remote-operations="true"
              >
                <DxScrolling
                  :use-native="true"
                  :scroll-by-content="true"
                  :scroll-by-thumb="true"
                  show-scrollbar="onHover"
                />
                <DxSelection mode="single" />
                <DxColumn data-field="id" sort-order="desc" :visible="false" />
                <DxColumn
                  data-field="perkara"
                  caption="Perkara"
                  :filter-operations="['contains']"
                />
                <DxColumn
                  data-field="kasus_hukum"
                  caption="Kasus"
                  :allow-filtering="false"
                />
                <DxColumn
                  data-field="materi"
                  caption="Materi/Gugatan"
                  :allow-filtering="false"
                />
                <DxColumn
                  data-field="direktorat.name"
                  caption="Pemrakarsa"
                  :allow-filtering="false"
                />
                <DxColumn
                  data-field="tanggal"
                  caption="Tanggal"
                  :customize-text="inCaseEmptyDateDx"
                />
                <DxColumn
                  data-field="penggugat"
                  caption="Penggugat"
                  :allow-filtering="false"
                />
                <DxColumn
                  data-field="tergugat"
                  caption="Tergugat"
                  :allow-filtering="false"
                />
                <DxColumn type="buttons" caption="Aksi">
                  <DxButton
                    icon="fa fa-eye"
                    hint="Lihat"
                    :onClick="onClickView"
                    :visible="hasPermission('user/view')"
                  />
                  <DxButton
                    icon="fa fa-edit"
                    hint="Ubah"
                    :onClick="onClickEdit"
                    :visible="hasPermission('user/update')"
                  />
                  <DxButton
                    icon="fa fa-trash"
                    hint="Hapus"
                    :onClick="onClickDelete"
                    :visible="hasPermission('user/delete')"
                  />
                </DxColumn>
                <DxPaging :page-size="10" />
                <DxPager
                  :visible="true"
                  :show-page-size-selector="showPageSizeSelector"
                  :allowed-page-sizes="pageSizes"
                />
                <DxMasterDetail
                  :enabled="true"
                  template="masterDetailTemplate"
                />
                <template #masterDetailTemplate="{ data: advokasi }">
                  <DetailTemplate
                    :template-data="advokasi"
                    @add-progress="refreshData"
                    @delete-progress="refreshData"
                  />
                </template>
              </DxDataGrid>
            </div>

            <!--   Modal Tambah Advokasi  -->
            <b-modal
              v-model="showModalEdit"
              hide-footer
              size="xl"
              centered
              id="modal-edit-advokasi"
            >
              <template #modal-header="{ close }">
                <h5 class="title-card-custom">Edit Advokasi</h5>
              </template>
              <b-form @submit.stop.prevent="onSubmit">
                <b-form-group
                  id="perkara-group"
                  label-for="perkara"
                  label="Perkara"
                >
                  <b-form-input
                    id="perkara"
                    name="perkara"
                    placeholder="No. Perkara"
                    v-model="form.perkara"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="kasus_hukum-group"
                  label-for="kasus_hukum"
                  label="Kasus"
                >
                  <b-form-input
                    id="kasus_hukum"
                    name="kasus_hukum"
                    placeholder="Kasus"
                    v-model="form.kasus_hukum"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="materi-group"
                  label-for="materi"
                  label="Materi"
                >
                  <b-form-input
                    id="materi"
                    name="materi"
                    placeholder="Materi"
                    v-model="form.materi"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="penggugat-group"
                  label-for="penggugat"
                  label="Penggugat"
                >
                  <b-form-input
                    id="penggugat"
                    name="penggugat"
                    placeholder="Penggugat"
                    v-model="form.penggugat"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="tergugat-group"
                  label-for="tergugat"
                  label="Tergugat"
                >
                  <b-form-input
                    id="tergugat"
                    name="tergugat"
                    placeholder="Tergugat"
                    v-model="form.tergugat"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="directorate-group"
                  label-for="select-directorate"
                  label="Direktorat"
                >
                  <b-form-select
                    id="select-directorate"
                    v-model="form.direktorat"
                    :options="direktorats"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- Pilih Salah Satu --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <hr />
                <div class="d-flex justify-content-center">
                  <button
                    type="reset"
                    class="btn btn-delete mr-8"
                    @click="resetForm()"
                  >
                    Kembali
                  </button>
                  <button type="submit" class="btn btn-save">Simpan</button>
                </div>
              </b-form>
            </b-modal>

            <!--   Modal View Advokasi  -->
            <b-modal
              v-model="showModalView"
              hide-footer
              size="xl"
              centered
              id="modal-view-advokasi"
            >
              <template #modal-header="{ close }">
                <h5 class="title-card-custom">Lihat Advokasi</h5>
              </template>
              <b-form>
                <b-form-group
                  id="perkara-group"
                  label-for="perkara"
                  label="No. Perkara"
                >
                  <b-form-input
                    id="perkara"
                    name="perkara"
                    placeholder="No. Perkara"
                    v-model="formView.perkara"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="kasus_hukum-group"
                  label-for="kasus_hukum"
                  label="Kasus"
                >
                  <b-form-input
                    id="kasus_hukum"
                    name="kasus_hukum"
                    placeholder="Kasus"
                    v-model="formView.kasus_hukum"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="materi-group"
                  label-for="materi"
                  label="Materi"
                >
                  <b-form-input
                    id="materi"
                    name="materi"
                    placeholder="Materi"
                    v-model="formView.materi"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="penggugat-group"
                  label-for="penggugat"
                  label="Penggugat"
                >
                  <b-form-input
                    id="penggugat"
                    name="penggugat"
                    placeholder="Penggugat"
                    v-model="formView.penggugat"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="tergugat-group"
                  label-for="tergugat"
                  label="Tergugat"
                >
                  <b-form-input
                    id="tergugat"
                    name="tergugat"
                    placeholder="Tergugat"
                    v-model="formView.tergugat"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="directorate-group"
                  label-for="select-directorate"
                  label="Direktorat"
                >
                  <b-form-select
                    id="select-directorate"
                    v-model="formView.direktorat"
                    :options="direktorats"
                    disabled
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- Pilih Salah Satu --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <hr />
                <div class="d-flex justify-content-center">
                  <button
                    type="reset"
                    class="btn btn-delete mr-8"
                    @click="closeModal()"
                  >
                    Tutup
                  </button>
                </div>
              </b-form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxMasterDetail,
  DxButton,
  DxPaging,
  DxPager,
  DxSelection,
  DxScrolling
} from "devextreme-vue/data-grid";
import "devextreme/dist/css/dx.light.css";
import DetailTemplate from "./DetailTemplate.vue";
import CustomStore from "devextreme/data/custom_store";
import { handleErrors, isNotEmpty } from "@/core/appUtil/util";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { Workbook } from "exceljs";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { mapGetters } from "vuex";
import { dxDateEmpty } from "@/core/helpers/functions";

let token = "";
let searchQ = "";
let tahunQ = "0";

const store = new CustomStore({
  key: "id",
  load: function(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      // "filter",
      "totalSummary",
      "group",
      "groupSummary"
    ].forEach(function(i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    // search by name if exists
    if (searchQ.length > 0) {
      params += "&q=" + searchQ;
    }

    if (tahunQ !== "0") {
      params += "&tahun=" + tahunQ;
    }
    /*if (
      Array.isArray(loadOptions["filter"]) &&
      loadOptions["filter"].length > 0 &&
      loadOptions["filter"][0] === "name"
    ) {
      params += "&nama=" + loadOptions["filter"][2];
    }*/
    return fetch(process.env.VUE_APP_URL_LOCAL + `/advokasi${params}`)
      .then(response => response.json())
      .then(data => {
        if (data.data === null) {
          setTimeout(() => {
            document.querySelector("#refreshButton").click();
          }, 500);

        } else {
          return {
            data: data.data,
            totalCount: data.totalCount,
            summary: data.summary,
            groupCount: data.groupCount
          };
        }
      })
      .catch(() => {
        throw "Data Loading Error";
      });
  }
});
const dataGridRefKey = "advocacy-data-grid";
const swalDelete = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-delete",
    cancelButton: "btn btn-cancel"
  },
  buttonsStyling: false
});
const swalSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-save",
    cancelButton: "btn btn-delete"
  },
  buttonsStyling: false
});

export default {
  mixins: [dxDateEmpty],
  name: "TabelAdvokasi",
  components: {
    DxDataGrid,
    DxColumn,
    DxMasterDetail,
    DetailTemplate,
    DxButton,
    DxPaging,
    DxPager,
    DxSelection,
    DxScrolling
  },
  data() {
    return {
      showModalEdit: false,
      showModalView: false,
      dataSource: store,
      dataGridRefKey,
      showPageSizeSelector: true,
      pageSizes: [5, 10, 20],
      form: {
        id: null,
        pilihan: null,
        tahun: null,
        keterangan: null,
        kasus_hukum: "",
        materi: "",
        perkara: "",
        penggugat: "",
        tergugat: "",
        direktorat: null
      },
      formView: {
        id: null,
        pilihan: null,
        tahun: null,
        keterangan: null,
        kasus_hukum: "",
        materi: "",
        perkara: "",
        penggugat: "",
        tergugat: "",
        direktorat: null
      },
      tahuns: [
        {
          text: "Semua",
          value: "0"
        },
        // {
        //   text: "2021",
        //   value: "2021"
        // },
        // {
        //   text: "2020",
        //   value: "2020"
        // },
        // {
        //   text: "2019",
        //   value: "2019"
        // },
        // {
        //   text: "2018",
        //   value: "2018"
        // },
        // {
        //   text: "2017",
        //   value: "2017"
        // },
        // {
        //   text: "2016",
        //   value: "2016"
        // }
      ],
      direktorats: [],
      loading: false,
      search: ""
    };
  },
  mounted() {
    // Set dropdown tahuns
    // for (let i = new Date().getFullYear(); i >= 2016; i--) {
    //   this.tahuns.push({
    //     text: i,
    //     value: i
    //   });
    // }

    this.setTahunsDropdown()

    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
    })
      .then(() => {
        fetch(
          encodeURI(process.env.VUE_APP_URL_LOCAL + `/master/direktorat?take=100`),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + token
            }
          }
        )
          .then(handleErrors)
          .then(response => response.json())
          .then(items => {
            const detailMenu = items.data;
            let filtered = detailMenu.filter(member => member.name !== "N/A");
            let selectedOptions;
            selectedOptions = [];
            filtered.map(i => {
              return selectedOptions.push({
                text: i.name,
                value: i.id
              });
            });
            this.direktorats = selectedOptions;
          });
      })

  },
  computed: {
    ...mapGetters({
      hasPermission: "token/hasPermission"
    }),
    dataGrid: function() {
      return this.$refs[dataGridRefKey].instance;
    }
  },
  methods: {
    selectionChanged(e) {
      e.component.collapseAll(-1);
      e.component.expandRow(e.currentSelectedRowKeys[0]);
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Advokasi");

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Advokasi.xlsx"
          );
        });
      });
      e.cancel = true;
    },
    refreshData() {
      this.dataGrid.refresh();
    },
    exportToExcel() {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Advokasi");

      exportDataGrid({
        component: this.dataGrid,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Advokasi.xlsx"
          );
        });
      });
    },
    exportToPDF() {
      const doc = new jsPDF({ orientation: "l" });
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: this.dataGrid
      }).then(() => {
        doc.save("Advokasi.pdf");
      });
    },
    onSelectTahun() {
      tahunQ = this.form.tahun;
      this.refreshData();
    },
    async searchAdvocacy() {
      searchQ = this.search;
      let context = this;
      this.loading = true;
      this.notFound = false;
      this.show = false;
      await this.$store
        .dispatch("searchAdvocacy", this.search)
        .then(() => {
          this.refreshData();
          this.loading = false;
        })
        .catch(async function(err) {
          context.loading = false;
          await Swal.fire({
            title: err.message,
            text: "",
            icon: "warning",
            confirmButtonColor: "#063A69",
            confirmButtonText: "Tutup",
            cancelButtonText: false
          });
        });
    },
    onSubmit() {
      let id = this.form.id;
      let data = {
        direktorat_id: this.form.direktorat,
        kasus_hukum: this.form.kasus_hukum,
        materi: this.form.materi,
        perkara: this.form.perkara,
        penggugat: this.form.penggugat,
        tergugat: this.form.tergugat
      };

      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(process.env.VUE_APP_URL_LOCAL + `/advokasi/${id}`),
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              },
              body: JSON.stringify(data)
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              Swal.fire({
                position: "center",
                icon: data.status,
                title: "Data berhasil diubah",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetForm();
              this.refreshData();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal mengubah data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        kasus_hukum: "",
        materi: "",
        perkara: "",
        direktorat: null
      };

      this.$nextTick(() => {
        this.$bvModal.hide("modal-edit-advokasi");
      });
    },
    closeModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-view-advokasi");
      });
    },
    onClickDelete(e) {
      let id = e.row.data.id;
      swalDelete.fire({
        text: "Yakin ingin hapus data?",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        confirmButtonText: "Hapus",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(process.env.VUE_APP_URL_LOCAL + `/advokasi/${id}`),
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              }
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              console.log(data);
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil dihapus",
                showConfirmButton: false,
                timer: 1500
              });
              this.refreshData();
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    onClickEdit(e) {
      const data = e.row.data;
      this.form.kasus_hukum = data.kasus_hukum;
      this.form.materi = data.materi;
      this.form.perkara = data.perkara;
      this.form.tergugat = data.tergugat;
      this.form.penggugat = data.penggugat;
      this.form.direktorat = data.direktorat_id;
      this.form.id = data.id;
      this.setDataModal = data;
      this.$root.$emit("bv::show::modal", "modal-edit-advokasi", e.row.data);
    },
    onClickView(e) {
      const data = e.row.data;
      this.formView.kasus_hukum = data.kasus_hukum;
      this.formView.materi = data.materi;
      this.formView.perkara = data.perkara;
      this.formView.tergugat = data.tergugat;
      this.formView.penggugat = data.penggugat;
      this.formView.direktorat = data.direktorat_id;
      this.formView.id = data.id;
      this.$root.$emit("bv::show::modal", "modal-view-advokasi", e.row.data);
    },
    setTahunsDropdown() {
      fetch(
        encodeURI(process.env.VUE_APP_URL_LOCAL + `/advokasi/years`), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          const years = items.data;

          if (years) {
            years.sort((n1, n2) => n1 - n2)

            years.map(i => {
              return this.tahuns.push({
                text: i,
                value: i
              });
            });
          }
        });
    }
  }
};
</script>

<style>
#grid-container {
  height: 440px;
}
.hidden-button {
  display: none;
}
</style>
